function serviceSelectedFromTooltip(service, date, chartType) {
  let obj = {
    service: service,
    date: date,
    chartType,
  };

  if (
    window.billingComponentReference &&
    window.location.href.indexOf("billing") > -1
  ) {
    window.billingComponentReference.zone.run(() => {
      window.billingComponentReference.callGetUsageTypesforServiceFunction(obj);
    });
  }


  if (
    window.storageComponentReference &&
    window.location.href.indexOf("storage") > -1
  ) {
    window.storageComponentReference.zone.run(() => {
      window.storageComponentReference.callGetUsageTypesforServiceFunction(obj);
    });
  }

  if (
    window.bandwidthComponentReference &&
    window.location.href.indexOf("bandwidth") > -1
  ) {
    window.bandwidthComponentReference.zone.run(() => {
      window.bandwidthComponentReference.callGetUsageTypesforServiceFunction(
        obj
      );
    });
  }
  if (
    window.wasabiComponentReference &&
    window.location.href.endsWith("wasabi")
  ) {
    window.wasabiComponentReference.zone.run(() => {
      window.wasabiComponentReference.callGetStorageTypesforServiceFunction(
        obj
      );
    });
  }
  if (
    chartType === "standardChart" &&
    window.wasabiCalcComponentReference &&
    window.location.href.indexOf("wasabi-calculator") > -1
  ) {
    window.wasabiCalcComponentReference.zone.run(() => {
      window.wasabiCalcComponentReference.callGetStorageTypesforServiceFunction(
        obj
      );
    });
  }

  if (
    chartType === "glacierChart" &&
    window.wasabiGlacierCalcComponentReference &&
    window.location.href.indexOf("wasabi-calculator") > -1
  ) {
    window.wasabiGlacierCalcComponentReference.zone.run(() => {
      window.wasabiGlacierCalcComponentReference.callGetStorageTypesforServiceFunction(
        obj
      );
    });
  }
}

function stopCloudMapperDiagramSpinner() {
  if (
    window.CloudMapperMainComponentReference &&
    window.location.href.indexOf("cloud-mapper") > -1
  ) {
    window.CloudMapperMainComponentReference.zone.run(() => {
      window.CloudMapperMainComponentReference.callStopCloudMapperDiagramSpinnerFunction();
    });
  }
}
